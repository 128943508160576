import React from "react";
import Components from "@/components/components";
import SbEditable from "storyblok-react";
import styles from "./layout.module.scss";
import classnames from "classnames";
import LayoutRow from "@/components/Layout/layout-row";
import { LayoutRowStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: LayoutRowStoryblok;
  className?: string;
}

const SbLayoutRow = (props: Props) => {
  const {
    blok: { content, space_above = "" },
    className,
  } = props;
  const classes = classnames(styles.layoutItem, styles[space_above]);

  return (
    // @ts-expect-error
    <SbEditable content={props.blok}>
      <LayoutRow className={className}>
        {content?.map((blok) => {
          return React.createElement(Components(blok.component), {
            key: blok._uid,
            className: classes,
            blok,
          });
        })}
      </LayoutRow>
    </SbEditable>
  );
};

export default SbLayoutRow;
